// src/pages/ProductsPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';

const CustomersPage = () => {
    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editingCustomer, setEditingCustomer] = useState(null);
    const [formData, setFormData] = useState({
        Nama: '',
        Umur: '',
        Asal: '',
        NoHp: ''
    });

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found');
            }
    
            const response = await axios.get('https://pbkk-golang.nubaim.me/api/v1/customer/', {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            setCustomers(response.data.data.customers);
            setIsLoading(false);
        } catch (error) {
            console.error('Error details:', error.response?.data);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.error || 'Failed to load customers'
            });
            setCustomers([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const customerData = {
                nama: formData.Nama,
                umur: parseInt(formData.Umur),
                asal: formData.Asal,
                no_hp: formData.NoHp
            };
    
            console.log('Sending data:', customerData);
    
            if (editingCustomer) {
                await axios.put(
                    `https://pbkk-golang.nubaim.me/api/v1/customer/${editingCustomer.ID}`, 
                    customerData,
                    {
                        headers: { 
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
            } else {
                await axios.post(
                    'https://pbkk-golang.nubaim.me/api/v1/customer/', 
                    customerData,
                    {
                        headers: { 
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
            }
    
            setShowModal(false);
            setEditingCustomer(null);
            setFormData({ Nama: '', Umur: '', Asal: '', NoHp: '' });
            await fetchCustomers();
            
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: `Customer ${editingCustomer ? 'updated' : 'added'} successfully`
            });
        } catch (error) {
            console.error('Error details:', error.response?.data);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.error || 'Failed to save customer'
            });
        }
    };

    const handleDelete = async (customerId) => {
        try {
            const result = await Swal.fire({
                title: 'Delete Customer',
                text: 'Are you sure you want to delete this customer?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#EF4444',
                cancelButtonColor: '#6B7280',
                confirmButtonText: 'Yes, delete it!'
            });
    
            if (result.isConfirmed) {
                const token = localStorage.getItem('token');
                await axios.delete(`https://pbkk-golang.nubaim.me/api/v1/customer/${customerId}`, {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
    
                await fetchCustomers();
                Swal.fire('Deleted!', 'Customer has been deleted.', 'success');
            }
        } catch (error) {
            console.error('Error details:', error.response?.data);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response?.data?.error || 'Failed to delete customer'
            });
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-800">Customers</h1>
                <button
                    onClick={() => {
                        setEditingCustomer(null);
                        setFormData({ Nama: '', Umur: '', Asal: '', NoHp: '' });
                        setShowModal(true);
                    }}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-700"
                >
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Add Customer
                </button>
            </div>

            {/* Customers Table */}
            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nama</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Umur</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Asal</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">NoHp</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {customers?.map((customer) => (
                            <tr key={customer.ID} className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">#{customer.ID}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.Nama}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.Umur}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.Asal}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.NoHp}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                    <button
                                        onClick={() => {
                                            setEditingCustomer(customer);
                                            setFormData(customer);
                                            setShowModal(true);
                                        }}
                                        className="text-blue-600 hover:text-blue-900 mr-4"
                                    >
                                        <PencilIcon className="h-5 w-5" />
                                    </button>
                                    {/* <button
                                        onClick={() => handleDelete(customer.ID)}
                                        className="text-red-600 hover:text-red-900"
                                    >
                                        <TrashIcon className="h-5 w-5" />
                                    </button> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-8 max-w-md w-full">
                        <h2 className="text-xl font-bold mb-4">{editingCustomer ? 'Edit Customer' : 'Add Customer'}</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Nama</label>
                                <input
                                    type="text"
                                    value={formData.Nama}
                                    onChange={(e) => setFormData({ ...formData, Nama: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Umur</label>
                                <input
                                    type="number"
                                    value={formData.Umur}
                                    onChange={(e) => setFormData({ ...formData, Umur: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Asal</label>
                                <input
                                    type="text"
                                    value={formData.Asal}
                                    onChange={(e) => setFormData({ ...formData, Asal: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">NoHp</label>
                                <input
                                    type="text"
                                    value={formData.NoHp}
                                    onChange={(e) => setFormData({ ...formData, NoHp: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                >
                                    {editingCustomer ? 'Update' : 'Add'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomersPage;

